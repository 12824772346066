"use client";

import { useEffect, useRef } from "react";
import "../styles/booking.css";
import Script from "next/script";
import usePersistStore from "@/hooks/usePersistStore";
import { useStore } from "@/stores";

function BookingForm({
  staticNode = "https://static.travelthru.com",
}: {
  staticNode?: string;
}) {
  const store = usePersistStore(useStore, (state) => state);
  const lastCurrency = useRef(store?.currency); // Store the last currency

  // Effect to reload when currency changes
  useEffect(() => {
    if (lastCurrency.current && store?.currency && lastCurrency.current !== store.currency) {
      lastCurrency.current = store.currency; // Update ref before reload
      window.location.reload();
    }
  }, [store?.currency]);

  useEffect(() => {
    const script = document.createElement("script");
    // script.src = "/assets/index.js";
    script.src = `${staticNode}/public/booking-script/assets/index.js`;
    script.async = true;
    document?.getElementById("booking-script-root")?.appendChild(script);

    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = `${staticNode}/public/booking-script/assets/index.css`;
    // link.href = "/assets/index.css";
    document.head.appendChild(link);
  }, []);
 
  useEffect(() => {
    if (window?.ttbsConfig) { 
      window.ttbsConfig.currency = store?.currency;
    }
  }, [store?.currency])

  return (
    <>
      <Script>
        {`
          window.ttbsConfig = {
            isHorizontal: true,
            bookingPageUrl: "/booking",
            completePageUrl: "/complete-booking",
            termsAndConditionsUrl: "https://www.travelthru.com/terms-and-conditions",
            clientId: "07f19cb1-94fa-43fa-ad68-d017547c11b8",
            companyClientId: "cea3ed8e-8cfe-4f1e-9666-8e595e83bae2",
            companyCompanyId: "e3f9eca9-c7c1-4717-8a0c-45505f9ecc37", 
            locale: "en",
            currency: "${store?.currency ?? "USD"}",
            fonts: {
              default: {
                family: "DM Sans",
                source:
                  "https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap",
              },
              heading: {
                family: "DegularDisplay-Semibold",
                source:
                  "${staticNode}/css?font=degular-complete/degular-display/web/DegularDisplay-Semibold.woff2",
              },
            },
            mapTheme: "light",
          }
        `}
      </Script>
      <div id="booking-script-root" className="w-full h-full" />
    </>
  );
}

export default BookingForm;
